@font-face {
  font-family: "Roboto_slab";
  src: local("Roboto_slab"),
    url(./fonts/Roboto_slab/RobotoSlab-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Gilda_display";
  src: local("Gilda_display"),
    url(./fonts/Gilda_display/GildaDisplay-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto_slab";
  font-weight: 900;
  src: local("Roboto_slab"),
    url(./fonts/Roboto_slab/RobotoSlab-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto_slab";
  font-weight: 900;
  src: local("Roboto_slab"),
    url(./fonts/Roboto_slab/RobotoSlab-Black.ttf) format("truetype");
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.name {
  font-family: Roboto_slab, "Times New Roman", Times, serif;
  font-weight: 500;
  font-size: larger;
}

.head {
  font-family: Roboto_slab, "Times New Roman", Times, serif;
}

.nav {
  font-family: Gilda_display, Arial, serif;
  font-weight: 700;
}

.para {
  font-family: Gilda_display, Arial, serif;
}

.back {
  background-color: #121212;
}

.cursor {
  position: fixed;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background: white;
  transform: translate(-50%, -50%);
  pointer-events: none;
  mix-blend-mode: difference;
}
